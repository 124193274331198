import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-10.css';

const PageTen = forwardRef((props, ref) => (
  <>
    <SEO title="Page 10" />
    <main className="page page-reverse page10">
      <div className="page__image-container">
        <StaticImage
          alt="Amanda with her Dad's sock over her head and Jimbugs in her pocket"
          aspectRatio={3500 / 4786}
          className="page__image page__image-border"
          height={750}
          layout="constrained"
          loading="eager"
          placeholder="tracedSVG"
          src="../../images/page-10.png"
        />
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text">
          <p>
            From inside her pocket came Jimbugs muffled voice. &ldquo;Now turn around three times saying...
          </p>
          <p>
            <span className="page__text-fancy ">
              Fizzle me gumbo, zappy baloo, I want your socks, but just one, not two.
            </span>
            &rdquo;
          </p>
          <p>
            When Amanda was finished she wasn&apos;t quite sure which way she was going.
          </p>
          <p>
            Jimbugs peeked out of the pocket. &ldquo;OK, now walk ten steps forward, then turn left and keep
            going.&rdquo;
          </p>
          <p>
            After a while they started going down. Further and further they went and Amanda could hear a faint
            noise in the distance. As it grew louder the path became smooth and even; although she had to crouch
            quite low so she wouldn&apos;t keep banging her head.
          </p>
          <p>
            &ldquo;You can uncover your eyes now,&rdquo; said Jimbugs, &ldquo;and take me out of your pocket.
            It&apos;s stuffy in here and I&apos;m covered in lint. And you&apos;ll have to go down on your knees.
            You are much too big to enter our workshop.&rdquo;
          </p>
        </div>
      </div>
      <PreviousPage to="/page-9" />
      <NextPage to="/page-11" />
    </main>
  </>
));

const PageTenContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageTen ref={textRef} />;
};

export default PageTenContainer;
